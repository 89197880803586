import { loadScript } from "./loadScript";
import { PAYMENT_CLIENT, cookiesBanner, enOption, jpOption, deOption } from "./payment.model";
const config = {
    en: enOption,
    de: deOption,
    "ja-JP": jpOption,
    jp: jpOption,
};
const { brand } = useHost();
// 0:未加载 1：加载中 2：加载完毕
export function usePayment({ modalCallback, message }: { modalCallback?: () => void; message?: any } = {}) {
    const { locale } = useI18n();

    const state = reactive<{ status: 0 | 1 | 2 }>({
        status: 0,
    });

    function loadPaymentJs() {
        state.status = 1;
        return loadScript(PAYMENT_CLIENT)
            .then(() => {
                state.status = 2;
            })
            .catch(() => {
                state.status = 0;
                throw new Error("Payment script load error");
            });
    }

    async function initPayment(value: "en" | "de" | "jp" | "ja-JP" = locale.value as any) {
        console.log("run initPayment");
        if (state.status === 1) return;
        if (state.status !== 2) {
            await loadPaymentJs();
            try {
                window.payment = new window.WsPayment(
                    Object.assign(
                        {
                            mode: "production",
                            themeColor: "#3D6DFF", // 主题色#f25929 #3D6DFF
                            brand: "nearhub", // 品牌 nuroum | nearhub | nearstream,
                            addButton: "Select Your NearHub", // 空列表按钮文案
                            addLink: "https://www.nearhub.us/hardware/select-nearhub", // 空列表按钮跳转链接
                            continueLink: window.location.origin + "/shop/nearhub-board-s55", // continue跳转链接
                            successUrl: window.location.origin + "/payment-result?status=success",
                            failedUrl: window.location.origin + "/payment-result?status=cancel",
                            host: "https://www.nearhub.us/api-strapi",
                            cookiesBanner,
                        },
                        config[value]
                    )
                );
                await window.payment.init();
                window.WsPayment.on(window.WsPayment.CHECKOUT, function (data: any) {
                    const gtag = (window as any).gtag;
                    const fbq = (window as any).fbq;
                    if (typeof fbq === "function") {
                        fbq("track", "InitiateCheckout", {
                            contents: data.map(function (item: any) {
                                return { id: item.spu };
                            }),
                        });
                    }
                    if (typeof gtag === "function") {
                        gtag("event", "conversion", { send_to: "AW-11301612072/gOZiCKSV2KAZEKjUg40q" });
                        gtag("event", "begin_checkout", { send_to: "G-9PC0PSXWHY" });
                    }
                    (window as any).uetq = (window as any).uetq || [];
                    (window as any).uetq.push("event", "begin_checkout", {});
                });
                window.WsPayment.on(window.WsPayment.ADD_TO_CART, function (data: any) {
                    const gtag = (window as any).gtag;
                    if (typeof gtag === "function") {
                        gtag("event", "conversion", {
                            send_to: "AW-11301612072/Kx39CPmAqvoYEKjUg40q",
                            value: data.price,
                            currency: "USD",
                        });
                        gtag("event", "add_to_cart", { send_to: "G-9PC0PSXWHY" });
                    }

                    (window as any).uetq = (window as any).uetq || [];
                    (window as any).uetq.push("event", "add_to_cart", {});
                });

                WsPayment.on(WsPayment.COOKIE_ACCEPT, () => {
                    const gtag = (window as any).gtag;
                    if (typeof gtag === "function") {
                        gtag("consent", "update", {
                            ad_storage: "granted",
                            ad_user_data: "granted",
                            ad_personalization: "granted",
                            analytics_storage: "granted",
                        });
                    }
                });

                WsPayment.on(WsPayment.COOKIE_DECLINE, () => {
                    const gtag = (window as any).gtag;
                    if (typeof gtag === "function") {
                        gtag("consent", "update", {
                            ad_storage: "denied",
                            ad_user_data: "denied",
                            ad_personalization: "denied",
                            analytics_storage: "denied",
                            region: ["DE"],
                        });
                    }
                });
                WsPayment.on("get.code", () => {
                    if (modalCallback) {
                        modalCallback();
                    }
                });

                window.WsPayment.on(window.WsPayment.COLLECT_EMAIL, function (data: string) {
                    if (!data) {
                        message.open("Email is empty", "error");
                        return;
                    }
                    if (!validEmail(data)) {
                        return message.open("Email is not a valid email", "error");
                    }
                    window.payment.currentDrawer.refs.subTotalRef.setLoading(true);
                    const gtag = (window as any).gtag;
                    if (typeof gtag === "function") {
                        gtag("event", "conversion", { send_to: "AW-11301612072/gOZiCKSV2KAZEKjUg40q" });
                        gtag("event", "begin_checkout", { send_to: "G-9PC0PSXWHY" });
                    }
                    const country = locale.value === "ja-JP" ? "jp" : locale.value;
                    handleEmlSubmit(data, "Checkout", (country === "en" ? "us" : country) as any)
                        .then(() => {
                            window.payment.currentDrawer.createOrder();
                            // window.payment.currentDrawer.refs.subTotalRef.setLoading(false);
                        })
                        .catch(() => {
                            window.payment.currentDrawer.refs.subTotalRef.setLoading(false);
                        });
                });
            } catch (error) {}
        }
    }

    function changeLocalConfig(value: "en" | "de" | "jp" | "ja-JP") {
        window.payment.setConfig(config[value]);
    }

    watch(
        () => locale.value,
        (newValue, oldValue) => {
            changeLocalConfig(newValue as any);
        }
    );

    return [state, { initPayment, changeLocalConfig }] as const;
}

export function loadScript(url: string) {
    return new Promise<void>(function (resolve, reject) {
        const script: any = document.createElement("script");
        script.type = "text/javascript";

        if (script.readyState) {
            // IE
            script.onreadystatechange = function () {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    resolve();
                }
            };
        } else {
            // Others
            script.onload = function () {
                resolve();
            };
        }

        script.onerror = function () {
            reject(new Error("Script load error for " + url));
        };

        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    });
}

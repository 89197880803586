export const PAYMENT_CLIENT = "https://resource.auditoryworks.co/lib/payment-20240619A.js";
// export const ERGO_PAYMENT_CLIENT = "https://resource.auditoryworks.co/lib/payment-20240619A.js";

// 商品推荐列表 （最多两个商品）
export const addList = [
    {
        img: "https://resource.auditoryworks.co/nearhub-v1/menu/products/s55.png?x-oss-process=image/resize,w_320/format,webp",
        name: "NearHub Board S55",
        price: "$3299",
        // discount: "$3299",
        spu_test: "price_1Nsi01AcSItaHs0xHCwoBnbo",
        spu: "price_1NuDN0AcSItaHs0xK0S8pNJR",
        unit: "$",
        totalNum: 1,
    },
    {
        name: "MagicPad ST1160",
        price: "$59",
        img: "https://resource.auditoryworks.co/nearhub-v1/cart/cart-st1160.png?x-oss-process=image/resize,w_320/format,webp",
        unit: "$",
        totalNum: 1,
        spu: "price_1NuVxjAcSItaHs0x8ZdISKR0",
    },
];

export const deOption = {
    unit: "€",
    local: "de",
    collectEmail: false,
    // addButton: "Wählen Sie Ihren NearHub", // 空列表按钮文案
    addList: [
        {
            img: "https://resource.auditoryworks.co/nearhub-v1/menu/products/s55.png?x-oss-process=image/resize,w_320/format,webp",
            name: "NearHub Board S55",
            price: "€3299",
            currency: "eur",
            // discount: "$3299",
            spu_test: "price_1Nsi01AcSItaHs0xHCwoBnbo",
            spu: "price_1OR4ctAcSItaHs0xqCU9KmAA",
            unit: "€",
            totalNum: 1,
        },
        {
            name: "MagicPad ST1160",
            currency: "eur",
            price: "€59",
            img: "https://resource.auditoryworks.co/nearhub-v1/cart/cart-st1160.png?x-oss-process=image/resize,w_320/format,webp",
            unit: "€",
            totalNum: 1,
            spu: "price_1OR4gtAcSItaHs0xilQFLpRC",
        },
    ],
};

export const jpOption = {
    unit: "￥",
    local: "jp",
    collectEmail: false,
    // addButton: "Wählen Sie Ihren NearHub", // 空列表按钮文案
    addList: [
        {
            img: "https://resource.auditoryworks.co/nearhub-v1/menu/products/s55.png?x-oss-process=image/resize,w_320/format,webp",
            name: "NearHub Board S55",
            price: "¥469,800",
            currency: "jpy",
            // discount: "¥469,800",
            spu_test: "price_1Nsi01AcSItaHs0xHCwoBnbo",
            spu: "price_1OR4ctAcSItaHs0xqCU9KmAA",
            unit: "￥",
            totalNum: 1,
        },
        {
            name: "MagicPad ST1160",
            currency: "jpy",
            price: "¥8,400",
            img: "https://resource.auditoryworks.co/nearhub-v1/cart/cart-st1160.png?x-oss-process=image/resize,w_320/format,webp",
            unit: "￥",
            totalNum: 1,
            spu: "price_1P0gYRDjoobLrqJ5AlvgIkyO",
        },
    ],
};

export const enOption = {
    unit: "$",
    local: "en",
    collectEmail: false,
    addList: [
        {
            img: "https://resource.auditoryworks.co/nearhub-v1/menu/products/s55.png?x-oss-process=image/resize,w_320/format,webp",
            name: "NearHub Board S55",
            price: "$3299",
            // discount: "$3299",
            spu_test: "price_1Nsi01AcSItaHs0xHCwoBnbo",
            spu: "price_1NuDN0AcSItaHs0xK0S8pNJR",
            unit: "$",
            totalNum: 1,
        },
        {
            name: "MagicPad ST1160",
            price: "$59",
            img: "https://resource.auditoryworks.co/nearhub-v1/cart/cart-st1160.png?x-oss-process=image/resize,w_320/format,webp",
            unit: "$",
            totalNum: 1,
            spu: "price_1NuVxjAcSItaHs0x8ZdISKR0",
        },
    ],
};

export const cookiesBanner = {
    backgroundColor: "#333",
    textColor: "#fff",
    acceptBgColor: "#fff",
    acceptTextColor: "#333",
    declineBgColor: "#333",
    declineTextColor: "#fff",
    privacyUrl: "/connect/privacy-statement",
};
